import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.section`
  background: url('/hero-img.png') no-repeat center center;
  background-size: cover;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  position: relative;
`;

const HeroText = styled.h1`
  font-size: 34px; /* Adjusted font size */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; /* Black outline */
`;

const BlackBar = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px; /* Set the height for the black bar */
  background-color: black;
`;

const Hero = () => (
  <HeroContainer id="home">
    <HeroText>constructing creativity</HeroText>
    <BlackBar />
  </HeroContainer>
);

export default Hero;
