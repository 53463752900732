import React from 'react';
import styled from 'styled-components';
import { useForm, ValidationError } from '@formspree/react';

const ContactContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 100px 100px 50px 40px; /* Increased padding for top and sides */
  background-color: #333;
  color: white;
  letter-spacing: 0.05em;
`;

const ContactInfo = styled.div`
  flex: 1;
  padding-right: 60px; /* Increased right padding */
  text-align: left;

  p {
    line-height: 1.8;
    margin-right: 20px; /* Added margin to make the paragraph narrower */
  }
`;

const ContactTitle = styled.h2`
  background-color: #333;
  color: white;
  display: inline-block;
  padding: 5px 10px;
  font-size: 30px; /* Adjusted font size */
`;

const ContactForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 50px; /* Increased top margin to lower the form */
  padding-right: 50px; /* Added padding to the right side */

  input, textarea {
    width: 100%;
    max-width: 500px;
    margin: 5px 0 15px;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #d3d3d3;
  }

  button {
    padding: 12px 24px;
    font-size: 16px;
    color: white;
    background-color: #444;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    align-self: flex-end;
  }

  button:hover {
    background-color: #555;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 90%;
  max-width: 500px;
  margin: 10px 0;

  label {
    align-self: flex-end;
    color: white;
    margin-bottom: 2px;
    text-align: right;
  }
`;

const Contact = () => {
  const [state, handleSubmit] = useForm("mbjnvann");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const emailInput = e.target.elements.email.value;
    if (!validateEmail(emailInput)) {
      alert('Please enter a valid email address.');
      return;
    }
    handleSubmit(e);
  };

  if (state.succeeded) {
    return (
      <ContactContainer id="contact">
        <ContactInfo>
          <ContactTitle>contact me</ContactTitle>
          <p>Need a webpage? Looking for a creative and reliable dev? Send me a message and I will get back to you within 48 hours.</p>
        </ContactInfo>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#d3d3d3', borderRadius: '5px', padding: '30px' }}>
          <p>thanks for your message. i normally respond within 48 hours.</p>
        </div>
      </ContactContainer>
    );
  }

  return (
    <ContactContainer id="contact">
      <ContactInfo>
        <ContactTitle>contact me</ContactTitle>
        <p>Need a webpage? Looking for a creative and reliable dev? Send me a message and I will get back to you within 48 hours.</p>
      </ContactInfo>
      <ContactForm onSubmit={handleFormSubmit}>
        <InputContainer>
          <label htmlFor="name">name</label>
          <input
            id="name"
            type="text"
            name="name"
            required
          />
          <ValidationError 
            prefix="Name" 
            field="name"
            errors={state.errors}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="email">email</label>
          <input
            id="email"
            type="email"
            name="email"
            required
          />
          <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="message">message</label>
          <textarea
            id="message"
            name="message"
            rows="5"
            required
          ></textarea>
          <ValidationError 
            prefix="Message" 
            field="message"
            errors={state.errors}
          />
        </InputContainer>
        <button type="submit" disabled={state.submitting}>Send</button>
      </ContactForm>
    </ContactContainer>
  );
};

export default Contact;
