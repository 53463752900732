import React from "react";
import projects from "../ProjectsData";
import "./Projects.css";

const Projects = () => {
  return (
    <div className="projects" id="projects">
      <h2>Projects</h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              <img src={process.env.PUBLIC_URL + project.imageUrl} alt={project.title} />
              <div className="project-description">
                <p>{project.description}</p>
              </div>
            </a>
            <div className="project-title">{project.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
