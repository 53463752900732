const projects = [
    {
      title: "Livewire - Pattern Library",
      description: "A functional pattern library for a company based on specific user requirements.",
      imageUrl: "../projectImages/lwPatternLib.png",
      link: "https://alexisoblivious.github.io/livewire-pattern-library",
    },
    {
      title: "Livewire - Data Dashboard",
      description: "A functional data dashboard for a company based on specific user requirements.",
      imageUrl: "../projectImages/lwDataDash.png",
      link: "https://alexisoblivious.github.io/livewire-data-dashboard",
    },
    {
      title: "One Free Slice",
      description: "An API project which promises free pizza but does not deliver (pizza).",
      imageUrl: "../projectImages/freeSlice.png",
      link: "https://alexisoblivious.github.io/one-free-slice",
    },
    {
      title: "Merchant's Exchange",
      description: "A Vanilla Javascript-only storefront project.",
      imageUrl: "../projectImages/merchExc.png",
      link: "https://alexisoblivious.github.io/rs-store",
    },
    {
      title: "Purrfect Rosette Bengals",
      description: "A small business webpage created with pure CSS.",
      imageUrl: "../projectImages/prb.png",
      link: "https://alexisoblivious.github.io/purrfect-rosette-bengals",
    },
    {
      title: "MLB App",
      description: "An app that uses the MLB API to find game stats and highlights.",
      imageUrl: "../projectImages/mlb.png",
      link: "https://alexisoblivious.github.io/mlb-form",
    },
    // {
    //   title: "To-Do List",
    //   description: "A dynamic to-do list.",
    //   imageUrl: "",
    //   link: "https://alexisoblivious.github.io/to-do-app",
    // }

  ];
  
  export default projects;