import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  background-color: #1d1d1d;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid black;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

const Logo = styled.div`
  color: white;
  font-size: 34px; /* Adjusted font size */
  letter-spacing: 0.25em;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  padding-right: 35px; /* Added padding to the right */

  a {
    background-color: black;
    color: white;
    margin: 0 15px;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 1.2rem; /* Adjusted font size */
    border-radius: 3px;
  }
`;

const Header = () => (
  <HeaderContainer>
    <Logo>alexis piercey</Logo>
    <Nav>
      <a href="#home">home</a>
      <a href="#projects">projects</a>
      <a href="#about">about</a>
      <a href="#contact">contact</a>
    </Nav>
  </HeaderContainer>
);

export default Header;
