import React from 'react';
import styled from 'styled-components';

const AboutContainer = styled.section`
  padding: 100px 80px 50px 20px; /* Increased top and right padding */
  background-color: #333;
  color: white;
  text-align: left;
`;

const AboutTitle = styled.h2`
  background-color: #333;
  color: white;
  display: inline-block;
  padding: 5px 100px;
  font-size: 30px; /* Adjusted font size */
`;

const AboutText = styled.div`
  margin: 50px 50px 50px auto; /* Adjusted margin to move paragraph towards the middle */
  font-size: 16px; /* Adjusted font size */
  line-height: 2; /* Increased line spacing */
  letter-spacing: 0.05em; /* Increased letter spacing */
  width: 40%; /* Limit paragraph width to control the text width */
  text-align: right; /* Align text to the right */
`;

const About = () => (
  <AboutContainer id="about">
    <AboutTitle>about me</AboutTitle>
    <AboutText>
      <div>my name is alexis piercey.</div>
      <div>i am a web developer.</div>
      <div>i am passionate about design, tech, and coffee.</div>
    </AboutText>
  </AboutContainer>
);

export default About;
